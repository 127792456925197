.o-spinner {
  user-select: none;
  display: flex; /* Cambia a flexbox para alinear elementos */
  align-items: center; /* Alinea verticalmente */
  justify-content: space-between; /* Espacia uniformemente los botones y el input */
  width: 110px;
  border: 1px solid #3D49CC;
  border-radius: 3px;
  background: #FFFFFF;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

@media screen and (min-width: 46em) {
  .o-spinner {
    height: 4.2rem;
  }
}

@media screen and (max-width: 45.99em) {
  .o-spinner {
    height: 2.8rem;
  }
}

/* Campo de entrada de número */
input[type="number"].o-spinner__number {
  flex: 1; /* Toma el espacio disponible entre los botones */
  text-align: center;
  color: #21262B;
  border: none;
  background: transparent;
  font-size: 1.82rem;
  line-height: 1.5;
  outline: none;
  -moz-appearance: textfield;
}

input[type="number"].o-spinner__number::placeholder {
  color: #4D5D6C;
}

input[type="number"].o-spinner__number::-webkit-inner-spin-button,
input[type="number"].o-spinner__number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Botones de incremento y decremento */
.o-spinner__sub,
.o-spinner__add {
  width: 3.2rem;
  height: 100%; /* Asegura que coincidan con la altura del contenedor */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  background: #3D49CC;
  border: none;
  cursor: pointer;
  font-size: 1.82rem;
  line-height: 1; /* Elimina diferencias de altura inesperadas */
}

.o-spinner__sub:hover, .o-spinner__add:hover {
  background: #2733AC;
}

/* Botón izquierdo */
.o-spinner__sub {
  border-radius: 3px 0 0 3px;
}

/* Botón derecho */
.o-spinner__add {
  border-radius: 0 3px 3px 0;
}
