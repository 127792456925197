.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Arial, sans-serif;
  }
  
  /* Oculta el checkbox original */
  .checkbox-container input {
    opacity: 0.5;
    position: absolute;
  }
  
  /* Crea un nuevo checkbox personalizado */
  .checkmark {
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  /* Cuando el checkbox está marcado */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Estilos para la información */
  .info .title {
    font-size: 16px;
  }
  
  .info .subtitle {
    font-size: 12px;
  }
  